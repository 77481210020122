import {FeatureToggle} from '@/config/featureToggles'
import {isFeatureEnabled} from '@/helpers/featureToggle'
import {PageError403} from '@/pages/Errors/403'
import {PageError500} from '@/pages/Errors/500'
import {Logout} from '@/pages/Logout'
import {LoginCallback} from '@okta/okta-react'
import {Route, Routes} from 'react-router-dom'
import {ROUTES} from '../../config/constants'
import {ProtectedRoutes} from '../../helpers/ProtectedRoutes'
import {Home, PageError404} from '../../pages'
import {SettingsPage} from '../../pages/SettingsPage'
import TriggerBasedNotification from '../../pages/TriggerBasedNotification'
import {Reports} from '@/pages/Reports'

export const AppRouter = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        {/* Features */}
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.LIST_NBAS} element={<Home />} />
        <Route path={ROUTES.EDIT_NBA} element={<Home />} />
        <Route path={ROUTES.LOGOUT} element={<Logout />} />
        <Route path={ROUTES.SETTINGS} element={<SettingsPage />} />
        <Route path={ROUTES.NBA_REPORTS} element={<Reports />} />
        <Route path={ROUTES.TASK_REPORTS} element={<Reports />} />

        {isFeatureEnabled(FeatureToggle.triggerBasedNotification) && (
          <Route path={ROUTES.EVENTS} element={<TriggerBasedNotification />} />
        )}
        {!isFeatureEnabled(FeatureToggle.isOktaGlobal) && <Route path={ROUTES.LOGIN} element={<Home />} />}
      </Route>

      {/* Auth */}
      <Route
        path={isFeatureEnabled(FeatureToggle.isOktaGlobal) ? ROUTES.LOGIN : ROUTES.SUCCESSFUL_LOGIN}
        element={<LoginCallback errorComponent={PageError403} />}
      />

      {/* Errors */}
      <Route path={ROUTES.ERROR_500} element={<PageError500 />} />
      <Route path={ROUTES.ERROR_403} element={<PageError403 />} />
      <Route path="*" element={<PageError404 />} />
    </Routes>
  )
}
